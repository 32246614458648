import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Seo';

const PravilaKoriscenja = () => {

    return (
        <Layout>
            <SEO title='Pravila Koriscenja' />
            <h2>Uslovi korišćenja</h2>
            <p>Dobrodošli na nezavisni informativni internet portal www.vodiczastare.rs. Vaš pristup, kao i korišćenje ovih internet stranica podleže Uslovima korišćenja i važećim zakonskim propisima koji uređuju ovu oblast.</p>
            <h4>Osnovna načela:</h4>

            <ul>
                <li>Internet portal www.vodiczastare.rs čiji osnivač je Udruženje “RAZVOJ UE” svoja načela i principe rada temelji na poštovanju domicijalnih i međunarodnih – pravnih akata (Ustav Republike Srbije, Opštu deklaraciju o ljudskim pravima, međunarodni akt o građanskim i političkim pravima i Evropska konvencija o ljudskim pravima)</li>
                <li>Sadržaj internet portala www.vodiczastare.rs dostupan je svima bez obzira na starosnu, rasnu, nacionalnu, polnu, seksualnu, versku ili drugu pojedinost</li>
                <li>Korišćenjem bilo kog dela internet portala www.vodiczastare.rs smatra se da prihvatate uslove korišćenja</li>
                <li>Sadržaj internet portala www.vodiczastare.rs koristite na sopstvenu odgovornost</li>
            </ul>

            <h4>Zaštita autorskih prava:</h4>
            
            <p>Portal www.vodiczastare.rs, svi tekstovi, logotipi, grafika, fotografije i ostali materijali na ovom sajtu vlasništvo su Udruženja “RAZVOJ UE” koje poseduje autorska prava kao i u programskom kodu te neovlašćeno korišćenje istih je zabranjeno na osnovu Zakona o autorskom i srodnim pravima;
            Zainteresovana pravna i fizička lica mogu BEZ saglasnosti www.vodiczastare.rs da koriste tekstualni material poštujući sledeća pravila:</p>
            <h5>Internet sajtovi</h5>
            <p>Obavezno vidljivo navođenje izvora (www.vodiczastare.rs) i objavu kompletnog linka (URL) preuzetog teksta, u tekstu i na kraju teksta</p>
            <h5>Televizija/ radio</h5>
            <p>Dozvoljeno je preuzimanje kompletnog teksta uz obavezno navođenje portala www.vodiczastare.rs kao izvora informacija najmanje jednom u nekoj od prve tri rečenice</p>
            <h5>Štampana izdanja</h5>
            <p>Dozvoljeno je preuzimanje kompletnog teksta uz obavezno navođenje portala www.vodiczastare.rs kao izvora informacija najmanje jednom u nekoj od prve tri rečenice
            Ukoliko smatrate da je www.vodiczastare.rs povredio Vaša autorska prava možete nam se obratiti putem e–mail adresu vodicstariji031@gmail.com i mi ćemo uzeti u razmatranje Vaš stav o uklanjanju zahtevanog sadržaja</p>  
            <h4>Zaštita privatnosti:</h4>
            <p>Udruženje “RAZVOJ UE” poštuje privatnost svojih korisnika i posetioca portala www.vodiczastare.rs i stoga neće otkrivati niti distribuirati podatke korisnika trećoj strani osim u slučaju kršenja Uslova korišćenja i u slučaju odobrenja korisnika</p>

            <h4>Korisnicima internet portala www.vodiczastare.rs nije dozvoljeno:</h4>

            <ul>
                <li>objavljivanje, slanje i razmena sadržaja koji krše postojeće srpske i/ili međunarodne zakone, sadržaja koji je uvredljiv, vulgaran, preteći, rasistički ili šovinistički kao i sadržaja koji je štetan na bilo koji drugi način</li>
                <li>objavljivanje, slanje i razmena informacija za koje posetilac zna ili pretpostavlja da su lažne, a čije bi korišćenje moglo da nanese štetu drugim korisnicima</li>
                <li>lažno predstavljanje, odnosno predstavljanje u ime drugog fizčkog ili pravnog lica</li>
                <li>objavljivanje, slanje i razmena neželjenih sadržaja korisnicima bez njihovog pristanka ili traženja, ili putem obmane svesno objavljivanje, slanje i razmena sadržaja koji sadrže programe napravljene u svrhu uništavanja ili ograničavanja rada softvera i/ili hardvera i telekomunikacione opreme</li>
            </ul>

            <h4>Linkovi (veze) sa spoljnim portalima i stranicama:</h4>
            <ul>
                <li>Na internet portalu www.vodiczastare.rs moguće je obljavljivanje linkova (veza) koji su izvan vlastitog domena</li>
                <li>www.vodiczastare.rs nije odgovoran za sadržaje koji se nalaze na linkovima (vezama) izvan vlastitog domena</li>
            </ul>

            <h4>Promena uslova korišćenja:</h4>
            <p>Udruženje “RAZVOJ UE” kao osnivač portala www.vodiczastare.rs zadržava pravo da bez najave promeni, izmeni ili dopuni Uslove korišćenja</p>
            <p>Udruženje “RAZVOJ UE” promene uslova korišćenja vršiće u skladu sa osnovnim načelom – načelom dobre namere i neće biti odgovoran za moguće posledice nastale takvom promenom</p>
            <p>Korišćenjem interet portala www.vodiczastare.rs smatra se da je posetilac upoznat sa promenama / izmenama / dopunama Uslova korišćenja.</p>
        </Layout>
    );
};

export default PravilaKoriscenja;